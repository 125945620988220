<template>
  <main class="content index-page">
    <section class="header">
      <article>
        <!-- :style="`background-image:url(${news[0].poster});background-position:center;background-size:cover;`" -->
        <div v-if="news[0]" class="first">
          <img :data-src="news[0].poster" src="/static_files/no-poster.png" :alt="news[0].title" class="lazyload">
          <div class="body">
            <ul class="tags">
              <li v-for="item in news[0].tags" :key="`first-o-${item.id}`">
                <nuxt-link :to="`/tag/${item.id}-${item.name}`">
                  <span>#</span>{{ item.name }}
                </nuxt-link>
              </li>
            </ul>
            <h2>
              {{ news[0].title }}
            </h2>
          </div>
          <nuxt-link :to="`/article/${news[0].pretty}`" class="readlink">{{ news[0].title }}</nuxt-link>
        </div>
        <div v-else :style="`background-position:center;background-size:cover;`" class="first">
          <div class="body">
            <ul class="tags">
              <li>
                <nuxt-link :to="`/tag/1-таг`">
                  <span>#</span>Таг
                </nuxt-link>
              </li>
            </ul>
            <h2>
              Название
            </h2>
          </div>
          <nuxt-link :to="`/`" class="readlink">Название</nuxt-link>
        </div>

        <div v-if="news.slice(1, 3).length" class="second">
          <div v-for="item in news.slice(1, 3)" :key="`second-o-${item._id}`" class="item">
            <div class="poster">
              <img :data-src="item.poster" src="/static_files/no-poster.png" :alt="item.title" class="lazyload">
            </div>
            <h2>
              {{ item.title }}
            </h2>
            <nuxt-link :to="`/article/${item.pretty}`" class="readlink">
              {{ item.title }}
            </nuxt-link>
          </div>
        </div>
      </article>
      <aside>
        <client-only>
          <div class="banner">
            <Rsya :payload="{ blockid:'R-A-1970027-1', page: 1 }" />
          </div>
        </client-only>
      </aside>
    </section>

    <section class="read-now">
      <h2 class="pH">
        Сейчас читают
      </h2>

      <div v-if="readNow.length" class="list">
        <div v-for="item in readNow" :key="`read-now-${item._id}`" class="item">
          <div class="poster">
            <img :data-src="item.poster" src="/static_files/no-poster.png" :alt="item.title" class="lazyload">
          </div>
          <h2>
            {{ item.title }}
          </h2>
          <nuxt-link :to="`/article/${item.pretty}`" class="readlink">
            {{ item.title }}
          </nuxt-link>
        </div>
      </div>
    </section>

    <hr>

    <section v-if="news.slice(3, 8).length" class="articles-block">
      <Articles :docs="news.slice(3, 8)" />
      <aside>
        <client-only>
          <div v-if="isDesktop" class="banner sticky" data-block="0">
            <Rsya :payload="{ blockid:'R-A-1970027-2', page: 1 }" />
          </div>
        </client-only>
      </aside>
    </section>

    <section class="parther-news">
      <h2 class="pH">
        Новости партнеров
      </h2>
      <!-- <div class="list">
        <div class="item">
          <div class="poster">
            <img
              :data-src="`https://n1s1.hsmedia.ru/ba/f2/c3/baf2c3adcad17e4af53ca8f113016471/316x196_0xac120003_15884535431636807352.jpeg`"
              :alt="`text`"
              src="/static_files/no-poster.png"
              class="lazy"
            />
          </div>
        </div> -->
        <Rsya :payload="{ blockid:'R-A-1970027-3', page: 1 }" />
    </section>

    <hr>

    <section v-if="news.slice(8).length" class="articles-block">
      <Articles :docs="news.slice(8)" />
      <client-only>
        <aside v-if="news.slice(8).length > 3">
          <div class="banner sticky" data-block="1">
            <Rsya :payload="{ blockid:'R-A-1970027-2', page: 2 }" />
          </div>
        </aside>
      </client-only>
    </section>

    <div v-if="!lastPage && news.length >= 15" class="show-more">
      <button @click="showMore(++page)">
        Больше материалов
      </button>
    </div>

    <div class="desc">
      <p>Добро пожаловать на наш сайт, посвященный ремонту, спорту, красоте и здоровью! Мы предлагаем широкий выбор статей и полезных советов, чтобы помочь вам достичь вашей цели в этих важных областях жизни.</p>

      <p>В разделе ремонта вы найдете статьи о различных аспектах обновления и восстановления дома или квартиры. Независимо от того, нужно ли вам руководство по декору, советы по ремонту или информация о последних тенденциях в отделке, мы стараемся предоставить все необходимое для успешного ремонта вашего жилья.</p>

      <p>В разделе спорта мы предлагаем полезные советы для тех, кто хочет вести активный образ жизни и достичь физической формы. От рекомендаций по тренировкам и питанию до советов по выбору правильного оборудования, мы поможем вам найти наиболее эффективные методы для достижения ваших спортивных целей.</p>

      <p>Красота и здоровье - важные аспекты нашей жизни, и мы уделяем им особое внимание. На нашем сайте вы найдете статьи о современных тенденциях в мире красоты, секретах ухода за кожей, волосами и ногтями, а также о здоровом образе жизни и профилактике различных заболеваний.</p>

      <p>Мы постоянно обновляем наш контент, чтобы предоставить вам самую актуальную и интересную информацию. Независимо от того, являетесь ли вы новичком или экспертом в данных областях, наш сайт будет полезным источником знаний и вдохновения.</p>

      <p>Присоединяйтесь к нашему сообществу, читайте наши статьи, делитесь своими мыслями и вопросами. Мы стремимся помочь вам стать лучше во всех сферах жизни, которые мы освещаем.</p>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Rsya: () => import('~/components/Rsya.vue'),
    Articles: () => import('~/components/Articles.vue')
  },
  computed: {
    ...mapState(['isDesktop'])
  },
  async asyncData({ $axios, query, redirect }) {
    if (Object.keys(query).length) {
      return redirect(`/`)
    }
    const { data } = await $axios.get(`/api/indexdata`)
    return {
      page: 1,
      news: data.news,
      readNow: data.read_now,
      gets: {},
      lastPage: false,
      adsBlock: {
        '0': false, '1': false
      }
    }
  },
  mounted() {
    if (this.isDesktop) window.addEventListener('scroll', this.stickyAds)
    window.addEventListener('scroll', this.showBlock)
  },
  beforeDestroy() {
    if (this.isDesktop) window.removeEventListener('scroll', this.stickyAds)
    window.removeEventListener('scroll', this.showBlock)
  },
  methods: {
    showBlock() {
      const doc = document.documentElement.clientHeight
      const list = document.querySelectorAll('.banner.sticky')
      list.forEach(block => {
        const visible = doc - block.getBoundingClientRect().top
        const blockName = block.dataset.block
        if (visible > 0 && !this.adsBlock[blockName]) {
          this.adsBlock[blockName] = true
        }
      })
    },
    async showMore(page) {
      if (this.gets.showMore) return
      this.gets.showMore = true
      const { data } = await this.$axios.get(`/api/articles?page=${page}`)
      if (data.length < 15) this.lastPage = true
      const news = this.news.concat(data)
      this.news = news
      delete this.gets.showMore
    },
    stickyAds() {
      const adsList = document.querySelectorAll('aside .banner.sticky')
      adsList.forEach((el, i) => {
        const parentBlock = el.parentElement.parentElement
        const parentBlockTop = parentBlock.getBoundingClientRect().top
        const parentBlockHeight = parentBlock.clientHeight
        const parentBlockPos = parentBlockTop + parentBlockHeight

        if (parentBlockTop < 60 && parentBlockPos > 660) {
          el.classList.add('fix')
          el.classList.remove('bottom')
        } else if (parentBlockPos < 660) {
          el.classList.remove('fix')
          el.classList.add('bottom')
        } else {
          el.classList.remove('fix')
          el.classList.remove('bottom')
        }
      })
    }
  }
}
</script>

<style src="~/assets/index.styl" lang="stylus" />
